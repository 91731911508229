import { useMediaQuery, useTheme } from "@mui/material";
import { useStoreContext } from "hooks/useStoreContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { PageDataContext } from "contexts/PageDataContext";
import axios from "axios";

export const useHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { selectedStore } = useStoreContext();
  const { userProfile } = useContext(PageDataContext);

  const anchorEl = useRef(null);

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobileMenuDialogOpen, setIsMobileMenuDialogOpen] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      setIsLoggingOut(true);
      const res = await axios.delete("/api/userSessions/me", {
        headers: {
          "X-Custom-Origin": window.location.origin,
        },
      });
      if (res.status === 200) {
        await new Promise((r) => setTimeout(r, 1000));
        window.location.reload();
      }
    } catch (e) {
      setIsLoggingOut(false);
    }
  }, []);

  const [yPosition, setYPosition] = useState(0);

  const onScroll = useCallback(() => {
    setYPosition(window.scrollY);
  }, []);

  const handleOpenUserMenu = useCallback(() => {
    setIsUserMenuOpen(true);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setIsUserMenuOpen(false);
  }, []);

  const openMobileDialogMenu = useCallback(
    () => setIsMobileMenuDialogOpen(true),
    [setIsMobileMenuDialogOpen]
  );

  const onMobileMenuDialogClose = useCallback(
    () => setIsMobileMenuDialogOpen(false),
    [setIsMobileMenuDialogOpen]
  );

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return {
    handleOpenUserMenu,
    handleCloseUserMenu,
    anchorEl,
    isUserMenuOpen,
    isMobile,
    selectedStore,
    userProfile,
    handleLogout,
    isLoggingOut,
    yPosition,
    onMobileMenuDialogClose,
    openMobileDialogMenu,
    isMobileMenuDialogOpen,
  };
};
