import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PageDataContext } from "contexts/PageDataContext";
import axios from "axios";
import useSWR from "swr";
import { JsUtility } from "models/common/JsUtility";
import { CardUserDetails, createUserSchema } from "models/common/UserCommon";
import { IProvinceFe } from "models/client/ProvinceFe";
import { ICityFe } from "models/client/CityFe";
import moment from "moment";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type UserSignupFormData = {
  email: string;
  name: string;
  surname: string;
  password: string;
  city: string;
  province: string;
  birthDate: Date;
  gender: string;
  socioCoop: boolean;
  eanCard: string;
  // privacy27: boolean; dynamically created
  // privacy28: boolean; dynamically created
};

const fetcherWithHeader = (url: string) =>
  axios.get(url, {
    headers: {
      "X-custom-origin": window.location.origin,
    },
  });

export const useUserSignupForm = () => {
  const { privacyDisclaimer } = useContext(PageDataContext);

  const [programmaticCitySetScheduled, setProgrammaticCitySetScheduled] =
    useState<{ scheduled: boolean; city: string | null }>({
      scheduled: false,
      city: null,
    });

  const [showSocioCoopCheckbox, setShowSocioCoopCheckbox] = useState(true);
  const [swrCitiesKey, setSwrCitiesKey] = useState<string | null>(null);
  const [swrValidateCardKey, setSwrValidateCardKey] = useState<string | null>(
    null
  );
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formData = useForm<UserSignupFormData>({
    resolver: yupResolver(createUserSchema),
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = formData;

  const [province, socioCoop, eanCard, birthDate] = useWatch({
    control,
    name: ["province", "socioCoop", "eanCard", "birthDate"],
  });
  const provincesResponse = useSWR("/api/provinces", fetcherWithHeader);
  const citiesResponse = useSWR(swrCitiesKey, fetcherWithHeader);
  const {
    data: cardUserDetailsResponse,
    isValidating: isLoadingCardVerification,
    mutate,
  } = useSWR<{
    cardUserDetails?: CardUserDetails;
    message?: string;
  }>(swrValidateCardKey, async (url) => {
    const token = await executeRecaptcha("signup_ean_card");

    return fetch(url, {
      method: "POST",
      body: JSON.stringify({
        token,
        eanCard,
        birthDate: moment(birthDate).format("DD/MM/YYYY"),
      }),
      headers: {
        "X-custom-origin": window.location.origin,
      },
    }).then((response) => response.json());
  });

  const provinces: IProvinceFe[] = useMemo(
    () => provincesResponse?.data?.data?.provinces ?? [],
    [provincesResponse]
  );

  const cities = useMemo<ICityFe[]>(
    () => citiesResponse?.data?.data?.cities ?? [],
    [citiesResponse]
  );

  const canVerifyCard = useMemo(
    () => Boolean(eanCard) && Boolean(birthDate) && Boolean(socioCoop),
    [eanCard, birthDate, socioCoop]
  );

  const filteredCities = useMemo(() => {
    return cities.map((c) => {
      return { label: c.name, value: c.idCity };
    });
  }, [cities]);

  const onVerifyCardPressed = useCallback(() => {
    if (swrValidateCardKey === null) {
      if (socioCoop && eanCard && birthDate) {
        setSwrValidateCardKey(`/api/card-user-details`);
      } else {
        setSwrValidateCardKey(null);
      }
    } else {
      mutate().then();
    }
  }, [socioCoop, eanCard, birthDate, swrValidateCardKey, mutate]);

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        try {
          const token = await executeRecaptcha("signup");

          setIsLoading(true);
          setMessageObj(undefined);

          let res = await axios.post(
            "/api/users",
            {
              token,
              socioCoop: Boolean(data.socioCoop),
              email: data.email,
              name: data.name,
              surname: data.surname,
              password: data.password,
              city: data.city,
              province: data.province,
              birthDate: moment(data.birthDate).format("DD/MM/YYYY"),
              gender: data.gender,
              eanCard: data.eanCard,
              privacyFlags: (privacyDisclaimer?.optinPrivacy ?? []).map(
                (item) => ({
                  idPrivacy: item.id,
                  value: data[`privacy${item.id}`],
                })
              ),
            },
            {
              headers: {
                "X-Custom-Origin": window.location.origin,
              },
            }
          );

          if (!res) {
            return res.data.payload;
          }

          setMessageObj(undefined);
          setSuccess(true);
        } catch (e) {
          const message = e.response.data.message;
          setMessageObj({ message, type: "ERROR" });
        } finally {
          setIsLoading(false);
        }
      }),
    [setSuccess, privacyDisclaimer, handleSubmit, executeRecaptcha]
  );

  useEffect(() => {
    if (province) {
      setSwrCitiesKey(`/api/cities?idProvince=${province}`);
    } else {
      setSwrCitiesKey(null);
    }
  }, [province]);

  useEffect(() => {
    if (cardUserDetailsResponse) {
      const { cardUserDetails, message } = cardUserDetailsResponse;

      if (cardUserDetails) {
        setShowSocioCoopCheckbox(false);
        setSwrValidateCardKey(null);

        const provinceOption = provinces.find(
          (province) => province.code === cardUserDetails?.provResIso
        );

        if (provinceOption) {
          setProgrammaticCitySetScheduled({
            scheduled: true,
            city: cardUserDetails.comuneRes,
          });
        }

        reset({
          name: cardUserDetails?.nome,
          surname: cardUserDetails?.cognome,
          birthDate: birthDate,
          city: cardUserDetails?.comuneRes,
          province: provinceOption?.idProvince,
          eanCard,
        });
        setMessageObj(undefined);
      } else if (message) {
        setMessageObj({ type: "ERROR", message });
      }
    }
  }, [cardUserDetailsResponse, reset, birthDate, provinces, eanCard]);

  useEffect(() => {
    if (programmaticCitySetScheduled.scheduled) {
      const city = cities.find(
        (city) =>
          city.name.toLowerCase() ===
          programmaticCitySetScheduled.city?.toLowerCase()
      );

      if (city) {
        reset({
          ...formData.getValues(),
          city: city.idCity,
        });
        setProgrammaticCitySetScheduled({ scheduled: false, city: null });
      }
    }
  }, [programmaticCitySetScheduled, cities, reset, formData]);

  return {
    formData,
    showSocioCoopCheckbox,
    canVerifyCard,
    isLoadingCardVerification,
    onVerifyCardPressed,
    onSubmit,
    success,
    provinces: provinces
      .map((e) => {
        return { label: e.name, value: e.idProvince };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
    cities: filteredCities.sort((a, b) => a.label.localeCompare(b.label)),
    isLoading,
    messageObj,
    privacyDisclaimer,
    socioCoop,
  };
};
