import {
  Grid,
  Stack,
  Typography,
  Link as LinkMui,
  Paper,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { memo } from "react";
import { AppContainer } from "components/AppContainer";
import { useUserSignupForm } from "./index.hooks";
import { FormTextField } from "components/_form/FormTextField";
import { FormPassword } from "components/_form/FormPassword";
import { FormCheckbox } from "components/_form/FormCheckbox";
import { FormProvider } from "react-hook-form";
import { CtaButton } from "components/CtaButton";
import { FormSelect } from "components/_form/FormSelect";
import { FormDatePicker } from "components/_form/FormDatePicker";
import { TaskAltOutlined } from "@mui/icons-material";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

type UserSignupProps = {};

export const UserSignup = memo(({}: UserSignupProps) => {
  return (
    <GoogleReCaptchaProvider
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      language="it"
    >
      <UserSignupForm />
    </GoogleReCaptchaProvider>
  );
});

UserSignup.displayName = "UserSignup";

export const UserSignupForm = memo(({}: UserSignupProps) => {
  const {
    formData,
    showSocioCoopCheckbox,
    canVerifyCard,
    isLoadingCardVerification,
    onVerifyCardPressed,
    onSubmit,
    provinces,
    cities,
    success,
    isLoading,
    messageObj,
    privacyDisclaimer,
    socioCoop,
  } = useUserSignupForm();

  return (
    <FormProvider {...formData}>
      <form onSubmit={onSubmit}>
        <AppContainer sx={{ mt: "40px" }}>
          {!success && (
            <Grid
              container
              spacing="20px"
              sx={{
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{ backgroundColor: "#FF0000", px: "36px", py: "42px" }}
                >
                  <Stack spacing={3}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "32px",
                      }}
                    >
                      Entra in Novacoop!
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Approfitta di tutti i vantaggi <strong>Socio Coop</strong>{" "}
                      entrando a far parte della nostra grande famiglia!
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                        pt: "20px",
                      }}
                    >
                      Sei già iscritto?
                    </Typography>
                    <Box>
                      <CtaButton text="Login" href="/accesso-soci" />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    py: "36px",
                    px: "42px",
                    border: "1px solid #E1E1E1",
                    boxShadow: "0px 2px 4px #0000000F",
                  }}
                >
                  <Stack spacing="43px">
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        fontSize: "21px",
                        color: "#AAA79A",
                      }}
                    >
                      Compila il form per completare la registrazione!
                    </Typography>
                    {showSocioCoopCheckbox && (
                      <Stack>
                        <FormCheckbox
                          key="socioCoop"
                          name="socioCoop"
                          color="secondary"
                          label="Sono già Socio Coop"
                        />
                      </Stack>
                    )}
                    {socioCoop ? (
                      <Stack direction="row" spacing="43px">
                        <FormTextField
                          key="eanCard"
                          color="secondary"
                          name="eanCard"
                          label="Numero Carta Socio Coop"
                          helperText="Inserisci il numero che trovi sul retro della tua tessera Socio Coop"
                          fullWidth
                        />
                        <FormDatePicker
                          key="birthDate"
                          name="birthDate"
                          helperText="Inserisci nel formato gg/mm/aaaa"
                          label="Data di nascita"
                          format="DD/MM/YYYY"
                          textFieldProps={{
                            color: "secondary",
                            fullWidth: true,
                          }}
                        />
                      </Stack>
                    ) : (
                      <Stack spacing="43px">
                        <FormTextField
                          key="email"
                          color="secondary"
                          name="email"
                          label="Indirizzo Email"
                        />
                        <Stack
                          direction={{ xs: "column", md: "row" }}
                          spacing="20px"
                        >
                          <FormTextField
                            key="name"
                            color="secondary"
                            fullWidth
                            name="name"
                            label="Nome"
                            sx={{
                              pb: { xs: 2, md: 0 },
                            }}
                          />
                          <FormTextField
                            key="surname"
                            color="secondary"
                            fullWidth
                            name="surname"
                            label="Cognome"
                            sx={{ mb: 2 }}
                          />
                        </Stack>
                        <Stack
                          direction={{ xs: "column", md: "row" }}
                          spacing="20px"
                        >
                          <FormPassword
                            key="password"
                            color="secondary"
                            fullWidth
                            name="password"
                            label="Password"
                            helperText="Inserisci una password di almeno 8 caratteri alfanumerici"
                          />
                          <FormPassword
                            key="confirmPassword"
                            color="secondary"
                            fullWidth
                            name="confirmPassword"
                            label="Conferma Password"
                            helperText="Inserisci una password di almeno 8 caratteri alfanumerici"
                          />
                        </Stack>
                        <Stack spacing="20px">
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing="20px"
                          >
                            <FormSelect
                              key="province"
                              options={provinces}
                              name="province"
                              label="Provincia di residenza"
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                              }}
                              fullWidth
                            />
                            <FormSelect
                              key="city"
                              options={cities}
                              name="city"
                              disabled={!formData.getValues("province")}
                              label="Città di residenza"
                              MenuProps={{
                                PaperProps: { sx: { maxHeight: 200 } },
                              }}
                              fullWidth
                            />
                          </Stack>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing="20px"
                          >
                            <FormDatePicker
                              key="birthDate"
                              name="birthDate"
                              helperText="Inserisci nel formato gg/mm/aaaa"
                              label="Data di nascita"
                              format="DD/MM/YYYY"
                              textFieldProps={{
                                color: "secondary",
                                fullWidth: true,
                              }}
                            />
                            <FormSelect
                              key="gender"
                              fullWidth
                              color="secondary"
                              options={[
                                { value: "M", label: "Maschio" },
                                { value: "F", label: "Femmina" },
                              ]}
                              name={"gender"}
                              label={"Genere"}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    )}

                    <Stack>
                      <Typography
                        sx={{
                          fontFamily: "Karla",
                          color: "#646259",
                          fontSize: 17,
                        }}
                      >
                        Leggi l&apos;
                        <LinkMui
                          href={privacyDisclaimer?.privacyLink}
                          target="_blank"
                          sx={{
                            textDecoration: "none",
                            color: "dodgerblue",
                          }}
                        >
                          {" "}
                          {privacyDisclaimer?.privacyLabel}
                        </LinkMui>
                      </Typography>
                      {(privacyDisclaimer?.optinPrivacy ?? []).map(
                        (item, index) => (
                          <Stack key={item.id}>
                            <FormCheckbox
                              color="secondary"
                              name={`privacy${item.id}`}
                              label={item.title}
                            />
                            <Typography
                              variant="caption"
                              sx={{
                                fontFamily: "Karla",
                                fontSize: 11,
                                lineHeight: "17px",
                                color: "#646259",
                              }}
                            >
                              {item.text}
                            </Typography>
                          </Stack>
                        )
                      )}
                      <Box sx={{ mt: "74px" }}>
                        {socioCoop ? (
                          <CtaButton
                            isLoading={isLoadingCardVerification}
                            disabled={!canVerifyCard}
                            onClick={onVerifyCardPressed}
                            text="Verifica carta"
                          />
                        ) : (
                          <CtaButton
                            isLoading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            text="Registrati"
                          />
                        )}
                      </Box>

                      {messageObj && (
                        <Alert
                          severity="error"
                          sx={{
                            pt: 2,
                            mt: 2,
                          }}
                        >
                          <Stack spacing={3} direction={"row"}>
                            <AlertTitle>{messageObj.message}</AlertTitle>
                          </Stack>
                        </Alert>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          )}
          {success && (
            <Box sx={{ pt: 4, pb: 4, textAlign: "center" }}>
              <TaskAltOutlined sx={{ fontSize: "100px", color: "#4BB543" }} />
              <Typography
                variant={"h2"}
                sx={{
                  pt: 2,
                  fontSize: "18px !important",
                  fontWeight: "500 !important",
                  lineHeight: "28px !important",
                }}
              >
                La registrazione è avvenuta con successo.
                <br /> Controlla la tua email, a breve riceverai un link di
                conferma per attivare il tuo account.
              </Typography>
            </Box>
          )}
        </AppContainer>
      </form>
    </FormProvider>
  );
});

UserSignupForm.displayName = "UserSignupForm";
