import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { useRouter } from "next/router";
import { debounce } from "lodash";
import { Resource } from "models/common/Resource";

const fetcherWithHeader = (url: string) =>
    axios.get(url, {
        headers: {
            "X-custom-origin": window.location.origin,
        },
    });


export const useSearchLandingPage = () => {
  const { query, push } = useRouter();

  const [searchText, setSearchText] = useState<string>(
    (query?.search as string) || ""
  );
  const [resultLimit, setResultLimit] = useState<number>(20);
  const [results, setResults] = useState<Resource[]>([]);

  useEffect(() => {
    if (!!query?.search) {
      setSearchText(query?.search as string);
    }
  }, [query]);

  const { data, isValidating: isFetchingResources } = useSWR(
    `/api/resources?q=${searchText}`,
    fetcherWithHeader,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      isPaused: () => !searchText.length,
    }
  );

  const setResources = useCallback(() => {
    const res = [];
    if (!!data?.data?.resources?.bestResults) {
      res.push(...data?.data?.resources?.bestResults);
    }
    if (!!data?.data?.resources?.otherResults) {
      res.push(...data?.data?.resources?.otherResults);
    }
    setResults([...res]);
  }, [setResults, data]);

  const fetchMore = useCallback(() => {
    if (results.length >= resultLimit)
      setResultLimit((prevState) => prevState + 10);
  }, [setResultLimit, results, resultLimit]);

  const debouncedFetchMore = useMemo(
    () =>
      debounce(
        () => {
          fetchMore();
        },
        500,
        { leading: true }
      ),
    [fetchMore]
  );

  const debouncedRouterPush = useMemo(() => {
    return debounce(
      async (e) => {
        if (!!searchText) {
          await push(
            `${(query?.slugs as string[]).join("/")}?search=${searchText}`,
            undefined,
            {
              shallow: true,
            }
          );
        }
      },
      100,
      { leading: true }
    );
  }, [push, query, searchText]);

  const onTextChange = useCallback(
    async (e) => {
      setSearchText(e.target.value);
      setResultLimit(20);
    },
    [setSearchText, setResultLimit]
  );

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await debouncedRouterPush(e);
    },
    [debouncedRouterPush]
  );

  const resetText = useCallback(() => {
    setSearchText("");
  }, []);

  useEffect(() => {
    setResources();
  }, [data, setResources]);

  return {
    searchText,
    onSearchTextChange: onTextChange,
    isFetchingResources,
    results,
    resultLimit,
    resetText,
    fetchMore: debouncedFetchMore,
    onSubmit,
  };
};
