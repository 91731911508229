import React, { memo } from "react";
import { useUserRecoveryPasswordForm } from "./index.hooks";
import { FormProvider } from "react-hook-form";
import { FormTextField } from "components/_form/FormTextField";
import { AppContainer } from "components/AppContainer";
import {
  Grid,
  Stack,
  Typography,
  Paper,
  Box,
  AlertTitle,
  Alert,
  CircularProgress,
} from "@mui/material";
import { CtaButton } from "components/CtaButton";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

type UserRecoveryPasswordProps = {
  loadingMessage?: string;
};

export const UserRecoveryPassword = memo((props: UserRecoveryPasswordProps) => {
  return (
    <GoogleReCaptchaProvider
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      language="it"
    >
      <UserRecoveryPasswordForm {...props} />
    </GoogleReCaptchaProvider>
  );
});

UserRecoveryPassword.displayName = "UserRecoveryPassword";

export const UserRecoveryPasswordForm = memo(
  ({
    loadingMessage = "Conferma dell'account in corso",
  }: UserRecoveryPasswordProps) => {
    const { formData, onSubmit, isLoading, messageObj } =
      useUserRecoveryPasswordForm();

    return (
      <FormProvider {...formData}>
        <form onSubmit={onSubmit}>
          <AppContainer sx={{ mt: "40px" }}>
            <Grid
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{ backgroundColor: "#009FDF", px: "36px", py: "42px" }}
                >
                  <Stack spacing={3}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "32px",
                      }}
                    >
                      Password dimenticata?
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Inserisci l&apos;email che hai utilizzato in fase di
                      registrazione per ricevere un codice di verifica.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Oppure..
                    </Typography>
                    <Box>
                      <CtaButton text="Registrati" href="/registrazione-soci" />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    py: "36px",
                    px: "42px",
                    border: "1px solid #E1E1E1",
                    boxShadow: "0px 2px 4px #0000000F",
                  }}
                >
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        fontSize: "21px",
                        color: "#AAA79A",
                      }}
                    >
                      Inserisci la tua email per recuperare la password...
                    </Typography>
                    <Stack spacing="20px">
                      {(isLoading || messageObj) && (
                        <Alert
                          severity={
                            isLoading
                              ? "info"
                              : messageObj.type === "SUCCESS"
                              ? "success"
                              : "error"
                          }
                          sx={{
                            pt: 2,
                            mb: 2,
                          }}
                        >
                          <Stack spacing={3} direction={"row"}>
                            <AlertTitle>
                              {isLoading
                                ? loadingMessage
                                : ""
                                ? "Accesso in corso..."
                                : messageObj.message}
                            </AlertTitle>
                            {isLoading && (
                              <CircularProgress
                                sx={{
                                  color: "#009FDF",
                                  fill: "#FFF",
                                }}
                                size={20}
                              />
                            )}
                          </Stack>
                        </Alert>
                      )}
                      <FormTextField
                        color="secondary"
                        name={"email"}
                        label={"Indirizzo Email"}
                        disabled={isLoading}
                        sx={{ mt: 2 }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ pb: 0.5 }}
                    >
                      <Box>
                        <CtaButton
                          type="submit"
                          text="Recupera Password"
                          disabled={isLoading}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </AppContainer>
        </form>
      </FormProvider>
    );
  }
);
UserRecoveryPasswordForm.displayName = "UserRecoveryPasswordForm";
