import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useMemo, useState } from "react";
import axios from "axios";
import { PageDataContext } from "contexts/PageDataContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const schema = yup.object().shape({
  email: yup.string().email().required("Inserisci un'email valida"),
  name: yup.string().required("Inserisci il tuo nome"),
  lastname: yup.string().required("Inserisci il tuo cognome"),
  text: yup.string().required("Inserisci il testo"),
  privacy: yup.boolean().oneOf([true], "Devi accettare la privacy"),
});

type EmailContactModuleFormData = {
  email: string;
  name: string;
  lastname: string;
  text: string;
  privacy: boolean;
};

export const useEmailContactModule = () => {
  const formData = useForm<EmailContactModuleFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      name: "",
      lastname: "",
      text: "",
      privacy: false,
    },
  });
  const { handleSubmit, reset } = formData;
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { slug } = useContext(PageDataContext);

  const handleReCaptchaVerify = useCallback(async () => {
    return await executeRecaptcha("contacts");
  }, [executeRecaptcha]);

  const handleRequest = useCallback(
    async (data) => {
      try {
        const token = await handleReCaptchaVerify();
        if (!token) {
          throw new Error("ReCAPTCHA token non valido");
        }
        setIsLoading(true);
        setMessageObj(undefined);
        setSuccess(false);
        const res = await axios.post(
          "/api/emailContactModuleDispatches",
          {
            email: data.email,
            name: data.name,
            lastname: data.lastname,
            text: data.text,
            slug,
            token,
          },
          {
            headers: {
              "X-Custom-Origin": window.location.origin,
            },
          }
        );

        if (!res) {
          return res.data.payload;
        }
        setMessageObj(undefined);
        setSuccess(true);
        reset();
      } catch (e) {
        console.error(e);
        const message = e?.response?.data?.message ?? e.message;
        setMessageObj({ message, type: "ERROR" });
      } finally {
        setIsLoading(false);
      }
    },
    [reset, handleReCaptchaVerify, slug]
  );

  const onSubmit = useMemo(
    () => handleSubmit(handleRequest),
    [handleSubmit, handleRequest]
  );

  return {
    onSubmit,
    formData,
    isLoading,
    success,
    messageObj,
  };
};
