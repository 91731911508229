import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { PageDataContext } from "../../../contexts/PageDataContext";
import axios from "axios";
import moment from "moment";
import useSWR from "swr";
import { JsUtility } from "models/common/JsUtility";

const schema = yup.object().shape({
  email: yup.string().required("Inserisci un'email valida"),
  password: yup.string().optional(),
  passwordCheck: yup
    .string()
    .optional()
    .oneOf([yup.ref("password"), null], "La password non corrisponde"),
  name: yup.string().required("Inserisci il tuo nome"),
  surname: yup.string().required("Inserisci il tuo cognome"),
  territory: yup.string().optional(), //not sure what we mean by territory
  province: yup.string().optional(),
  city: yup.string().optional(),
  birthDate: yup.string().optional(),
  gender: yup.string().optional(),
  socioCoop: yup.boolean().default(false),
  eanCard: yup.string().when("socioCoop", {
    is: (socioCoop) => socioCoop,
    then: yup
      .string()
      .required("Inserisci il tuo numero di tessera Socio Coop")
      .min(
        13,
        "Il numero di tessera Socio Coop deve contenere esattamente 13 caratteri"
      )
      .max(
        13,
        "Il numero di tessera Socio Coop deve contenere esattamente 13 caratteri"
      ),
  }),
});

type UserDashboardFormData = {
  email: string;
  name: string;
  surname: string;
  password: string;
  passwordCheck: string;
  territory: string;
  city: string;
  province: string;
  birthDate: Date;
  gender: string;
  socioCoop: boolean;
  eanCard: string;
  // privacy27: boolean; dynamically created
  // privacy28: boolean; dynamically created
};

const fetcherWithHeader = (url: string) =>
  axios.get(url, {
    headers: {
      "X-custom-origin": window.location.origin,
    },
  });

export const useUserDashboard = () => {
  const { userProfile } = useContext(PageDataContext);
  const provincesData = useSWR("/api/provinces", fetcherWithHeader);
  const provinces = useMemo(
    () => provincesData?.data?.data?.provinces ?? [],
    [provincesData]
  );
  const { privacyDisclaimer } = useContext(PageDataContext);
  const formData = useForm<UserDashboardFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: userProfile?.email || "",
      name: userProfile?.name || "",
      surname: userProfile?.surname || "",
      password: "",
      province: userProfile?.residenceProvince,
      city: userProfile?.residenceCity,
      birthDate: moment(userProfile?.birthDate, "DD/MM/YYYY").toDate(),
      gender: userProfile?.gender,
      socioCoop: userProfile?.fidelityCards?.[0]?.eanCard ? true : false,
      eanCard: userProfile?.fidelityCards?.[0]?.eanCard ?? "",
      ...Object.fromEntries(
        (privacyDisclaimer?.optinPrivacy ?? []).map((item) => [
          `privacy${item.id}`,
          userProfile?.privacy?.find?.((p) => p.id === item.id)?.value,
        ])
      ),
    },
  });
  const { control } = formData;
  const [province, socioCoop] = useWatch({
    control,
    name: ["province", "socioCoop"],
  });
  const citiesData = useSWR(`/api/cities?idProvince=${province}`, fetcherWithHeader);
  const cities = useMemo(
    () => citiesData?.data?.data?.cities ?? [],
    [citiesData]
  );
  const filteredCities = useMemo(() => {
    if (!province) {
      return [];
    } else
      return cities
        .filter((c) => c.idProvince === province)
        .map((c) => {
          return { label: c.name, value: c.idCity };
        });
  }, [province, cities]);

  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const handleRequest = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const res = await axios.patch(
          "/api/users",
          {
            email: data.email,
            name: data.name,
            surname: data.surname,
            password: data.password,
            passwordCheck: data.passwordCheck,
            residenceCity: data.city,
            residenceProvince: data.province,
            birthDate: JsUtility.formatDateCET(data.birthDate),
            gender: data.gender,
            privacyFlags: (privacyDisclaimer?.optinPrivacy ?? []).map(
              (item) => ({
                idPrivacy: item.id,
                value: data[`privacy${item.id}`],
              })
            ),
            eanCard: data.socioCoop ? data.eanCard : undefined,
          },
          {
            params: { type: "edit" },
            headers: { "X-Custom-Origin": window.location.origin },
          }
        );
        if (res.status === 200) {
          setMessageObj({
            message: "Dati aggiornati con successo!",
            type: "SUCCESS",
          });
        }
      } catch (e) {
        setMessageObj({
          message: e.response.data.message,
          type: "ERROR",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [privacyDisclaimer]
  );

  const { handleSubmit } = formData;

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        await handleRequest(data);
      }),
    [handleSubmit, handleRequest]
  );

  const handleDelete = useCallback(() => {
    // @TODO: delete user data
    console.log("Account eliminato.");
  }, []);

  return {
    formData,
    onSubmit,
    provinces: provinces
      .map((e) => {
        return { label: e.name, value: e.idProvince };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
    cities: filteredCities.sort((a, b) => a.label.localeCompare(b.label)),
    isLoading,
    handleDelete,
    userProfile,
    messageObj,
    setMessageObj,
    privacyDisclaimer,
    socioCoop,
  };
};
