import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import Timeout = NodeJS.Timeout;
import useSWR from "swr";
import axios from "axios";

const possibleSearchHints = [
  "Socio",
  "Coop",
  "Spesa",
  "Cibo",
  "Ristorante",
  "Risparmio",
  "Telefonia",
  "Salute",
];

const fetcherWithHeader = (url: string) =>
    axios.get(url, {
      headers: {
        "X-custom-origin": window.location.origin,
      },
    });


export const useAppSearchBar = () => {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const requestTimeout = useRef<Timeout>();

  const [isOpen, setIsOpen] = useState(false);
  const [barText, setBarText] = useState("");
  const [searchText, setSearchText] = useState("");

  const { data, isValidating: isFetchingResources } = useSWR(
    `/api/resources?q=${searchText}`,
    fetcherWithHeader,
    {
      isPaused: () => !searchText,
    }
  );

  const resources = useMemo(() => {
    return data?.data?.resources || {};
  }, [data]);

  const noResults = useMemo(() => {
    if (!data) return false;

    const noBestMatches = resources?.bestResults?.length === 0 ?? true;
    const noOtherMatches = resources?.otherResults?.length === 0 ?? true;

    return noBestMatches && noOtherMatches;
  }, [data, resources]);

  const searchHints = useMemo(() => {
    if (!barText || noResults) {
      return possibleSearchHints;
    }

    return possibleSearchHints.filter(
      (hint) =>
        hint.toLowerCase().includes(barText.toLowerCase()) && hint !== barText
    );
  }, [barText, noResults]);

  const hintClickCallbacks = useMemo(
    () => searchHints.map((hint) => () => setBarText(hint)),
    [searchHints]
  );

  const openMenuHandler = useCallback(() => {
    if (isUpMd) {
      setIsOpen(true);
    }
  }, [isUpMd]);

  const closeMenuHandler = useCallback((openState: boolean) => {
    setIsOpen(openState);
  }, []);

  const redirectToSearchPage = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (isUpMd) {
        return;
      }

      window.location.href = `/cerca-nel-sito?q=${barText}`;
    },
    [barText, isUpMd]
  );

  const onSearchBarTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const textInput = event.target.value;
      setBarText(textInput);

      if (textInput?.length >= 3) {
        openMenuHandler();
      } else {
        closeMenuHandler(false);
      }
    },
    [openMenuHandler, closeMenuHandler]
  );

  useEffect(() => {
    if (!isUpMd) {
      setIsOpen(false);
    }
  }, [isUpMd]);

  useEffect(() => {
    if (isUpMd) {
      if (requestTimeout.current) {
        clearTimeout(requestTimeout.current);
      }

      requestTimeout.current = setTimeout(() => {
        if (barText?.length >= 3) {
          setSearchText(barText);
        }
      }, 500);
    }
  }, [barText, isUpMd]);

  return {
    barText,
    isOpen,
    closeMenuHandler,
    isUpMd,
    onSearchBarTextChange,
    data: resources,
    isFetchingResources,
    searchHints,
    hintClickCallbacks,
    redirectToSearchPage,
  };
};
